<template>
  <Page :title="$t('system.title')" :classes="['page--static']">
    <div v-html="$t('system.content.paragraph_1')"></div>
    <div v-html="$t('system.content.paragraph_2')"></div>
    <img
      class="table"
      :src="`/img/${$i18n.locale}/info/ods-table.svg`"
      @error="$event.target.src = `/img/es/info/ods-table.svg`"
      alt="OpenODS Table"
    >
    <div v-html="$t('system.content.paragraph_3')"></div>
    <div v-html="$t('system.content.paragraph_4')"></div>
    <div>
      <ol>
        <li v-html="$t('system.content.phase_1')"></li>
        <li v-html="$t('system.content.phase_2')"></li>
        <li v-html="$t('system.content.phase_3')"></li>
        <li v-html="$t('system.content.phase_4')"></li>
      </ol>
    </div>
  </Page>
</template>

<script>
export default {
  name: 'SystemPage',
};
</script>

<style lang="scss" scoped>
.table {
  width: 100%;
}
ol li {
  list-style: decimal;
}
</style>
